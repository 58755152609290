import React, { Fragment } from 'react';
import s from './index.module.scss';
import { useWindowDimensions } from '../../hooks/useDimension';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Seo from '../../components/Seo';

const Success = () => {
  const { width, height } = useWindowDimensions();

  return (
    <Fragment>
      <Seo
        lang="es"
        description="Gracias por tu compra"
        title="Gracias por tu compra"
      />
      <Header />
      <div className={s.container}>
        <div className={s.box}>
          <div className={s.content}>
            <p className={s.yellow}>Gracias por tu compra.</p>
            <p className={s.text}>
              ¡Recuerda que tienes 24 horas para que tus amigos completen el
              pago para disfrutar tu Corona Session!
            </p>
          </div>
        </div>
        <Footer
          styles={{
            position: width <= 768 || height <= 450 ? 'static' : 'absolute',
          }}
        />{' '}
      </div>
    </Fragment>
  );
};

export default Success;
